const data = [
    {
        title : 'Ethereum',
        platformName:"Ethereum",
        image: "https://uploads-ssl.webflow.com/61a30c67b2eb0769954629e6/621eb1a1a420de50be689bb3_eth.jpg",
        titledescription: "Use SushiSwap and become a DEX Champion",
        details: [
            {
               title: 'ENS',
               taskName: 'ENS',
               description: 'Register ENS', 
               xp:  '100 XP',
               link: 'https://www.google.com/',
               image: "https://uploads-ssl.webflow.com/61a30c67b2eb0769954629e6/6225055d71a15047caaa385d_ensregistration.jpg"
            },
            {
                title: 'ETHTransfer',
                taskName: 'Transfer ETH',
                description: 'Transfer ETH to Mask',
                xp: '100 XP',
                link: 'https://www.google.com/',
                image: "https://uploads-ssl.webflow.com/61a30c67b2eb0769954629e6/622418abceb04629e712783a_3.jpg"
            },
            {
                title: 'AAVE Swap',
                 taskName: 'AAVE Swap',
                description: 'Swap ETH to AAVE',
                xp: '100 XP',
                link: 'https://www.google.com/',
                image: "https://uploads-ssl.webflow.com/61a30c67b2eb0769954629e6/622418ac3ba13ebafeab5d9b_swap%20sushiswap%20AAVE.jpg"
            },
            {
                title: 'AAVE',
                 taskName: 'AAVE',
                description: 'Stake AAVE',
                xp: '100 XP',
                link: 'https://www.google.com/',
                image: "https://uploads-ssl.webflow.com/61a30c67b2eb0769954629e6/622418ac549b6eb39594ac68_stake%20AAVE.jpg"
            }
        ]
    },
    {
        title : 'Klima',
        platformName:"Klima",
        image: "https://uploads-ssl.webflow.com/61a30c67b2eb0769954629e6/621eb1a0f0a7ca0208511681_klima.jpg",
        titledescription: "Earn yield and help fight Climate Change fefeegegergejwdijd",
        details: [
            {
               taskName: "Klima",
               description: 'Swap Matic for Klima', 
               xp:  '200 XP',
               link: 'https://www.google.com/',
               image: "https://uploads-ssl.webflow.com/61a30c67b2eb0769954629e6/622418ac7d2fd61caaf3b723_4.jpg"
            },
            {
                taskName: "Klima",
                description: 'Stake Klima',
                xp: '200 XP',
                link: 'https://www.google.com/',
                image: "https://uploads-ssl.webflow.com/61a30c67b2eb0769954629e6/622418acd91be159b3dcbdaf_5.jpg"
            }
        ]
    },
    {
        title : 'BNB',
        platformName: 'Binance',
        image: "https://uploads-ssl.webflow.com/61a30c67b2eb0769954629e6/621eb1a1211e8a04cea0b8b6_bnb.jpg",
        titledescription: "Use Binance Smart Chain to swap and stake tokens",
        details: [
            {
                taskName: "BNB",
               description: 'Binance to Metamask', 
               xp:  '100XP',
               link: 'https://www.google.com/',
               image: "https://uploads-ssl.webflow.com/61a30c67b2eb0769954629e6/622418ab43f314603daeebad_6.jpg"
            },
            {
                taskName: "Swap",
                description: 'Swap BNB-CAKE', 
               xp:  '100XP',
               link: 'https://www.google.com/',
               image: "https://uploads-ssl.webflow.com/61a30c67b2eb0769954629e6/622418ab1b2f107ed51db853_1.jpg"
            },
            {
                taskName: "CAKE",
                description: 'Stake CAKE',
                xp: '100 XP',
                link: 'https://www.google.com/',
                image: "https://uploads-ssl.webflow.com/61a30c67b2eb0769954629e6/622418ace99b98831575138e_2.jpg"
            }
        ]
    },
    {
        title : 'CoinBase',
        platformName: 'CoinBase',
        image: "https://uploads-ssl.webflow.com/61a30c67b2eb0769954629e6/621eb1a0211e8a9638a0b8b4_coinbase.jpg",
        titledescription: "Use Binance Smart Chain to swap and stake tokens",
        details: [
            {
                taskName: "Coinbase",
               description: 'Coinbase to MetaMask', 
               xp:  '100XP',
               link: 'https://www.google.com/',
               image: "https://uploads-ssl.webflow.com/61a30c67b2eb0769954629e6/622418abceb04629e712783a_3.jpg"
            },
            {
                taskName: "Swap",
                description: 'Swap USDT', 
               xp:  '100XP',
               link: 'https://www.google.com/',
               image: "https://uploads-ssl.webflow.com/61a30c67b2eb0769954629e6/622418ac28953e6eeb56f380_USDT%20to%20any%20token.jpg"
            }
        ]
    },
    {
        title : 'Polygon',
        platformName: 'Polygon',
        image: "https://uploads-ssl.webflow.com/61a30c67b2eb0769954629e6/621f90ccbb0d5e98fd8fb87f_matic.jpg",
        titledescription: "Use the famous Layer 2 Solution Polygon Matic",
        details: [
            {
                taskName: "DG",
            description: 'Swap Mat for DG', 
               xp:  '100XP',
               link: 'https://www.google.com/',
               image: "https://uploads-ssl.webflow.com/61a30c67b2eb0769954629e6/622418ab556df4475a6d4a81_matic%20to%20DG.jpg"
            },
        ]
    },
    {
       title : 'AVAX',
        platformName: 'AVAX',
        image: "https://uploads-ssl.webflow.com/61a30c67b2eb0769954629e6/621eb1a1211e8a1bf6a0b8b5_avax.jpg",
       titledescription: "Swap AVAX",
        details: [
           {
            description: 'Swap AVAX', 
              xp:  '100XP',
              link: 'https://www.google.com/',
              image: "https://uploads-ssl.webflow.com/61a30c67b2eb0769954629e6/622418ab43f31418ffaeebac_8.jpg"
            },
            {
                description: 'Stake AVAX', 
                  xp:  '100XP',
                  link: 'https://www.google.com/',
                  image: "https://uploads-ssl.webflow.com/61a30c67b2eb0769954629e6/622418ac15800973e94138a2_7.jpg"
                },
        ]
     },
     {
        title : 'Metamask',
         platformName: 'Metamask',
       image: "https://uploads-ssl.webflow.com/61a30c67b2eb0769954629e6/621eb1a10855b2feb9568578_mask.jpg",
       titledescription: "Send token to Metamask",
       details: [
            {
                taskName: "Metamask",
               description: 'Transfer to Metamask', 
             xp:  '100XP',
              link: 'https://www.google.com/',
              image: "https://uploads-ssl.webflow.com/61a30c67b2eb0769954629e6/622418ab4256011ae5a7f7a2_transfer%20ETH%20to%20metamask.jpg"
          },
       ]
  }
]




// const platformData = [
//     {
//         title : 'Ziliqa',
//         image: "https://cryptologos.cc/logos/sushiswap-sushi-logo.svg?v=018",
//         titledescription: "Use SushiSwap and become a DEX Champion",
//         details: [
//             {
//                 title: 'SushiSwap',
//                description: 'Swap USDT for a token on SushiSwap', 
//                xp:  '100 XP',
//                link: 'https://www.google.com/',
//                image: "https://uploads-ssl.webflow.com/61ac493358f04be987c2a86b/61ac493358f04bc73dc2a886_principle.svg"
//             },
//             {
//                 description: 'Swap USDT for WMEMO on SuhsiSwap',
//                 xp: '100 XP',
//                 link: 'https://www.google.com/',
//                 image: "https://uploads-ssl.webflow.com/61ac493358f04be987c2a86b/61ac493358f04bc73dc2a886_principle.svg"
//             },
//             {
//                 description: 'Stake WMEMO on Time Wonderland',
//                 xp: '100 XP',
//                 link: 'https://www.google.com/',
//                 image: "https://uploads-ssl.webflow.com/61ac493358f04be987c2a86b/61ac493358f04bc73dc2a886_principle.svg"
//             }
//         ]
//     },
//     {
//         title : 'Polygon',
//         image: "https://uploads-ssl.webflow.com/61ac493358f04be987c2a86b/61ac493358f04bc73dc2a886_principle.svg",
//         titledescription: "Earn yield and help fight Climate Change fefeegegergejwdijd",
//         details: [
//             {
//                 description: 'Swap Matic for Klima', 
//                xp:  '200 XP',
//                link: 'https://www.google.com/',
//                image: "https://uploads-ssl.webflow.com/61ac493358f04be987c2a86b/61ac493358f04bc73dc2a886_principle.svg"
//             },
//             {
//                 description: 'Stake Klima',
//                 xp: '200 XP',
//                 link: 'https://www.google.com/',
//                 image: "https://uploads-ssl.webflow.com/61ac493358f04be987c2a86b/61ac493358f04bc73dc2a886_principle.svg"
//             }
//         ]
//     },
//     {
//         title : 'Binance',
//         image: "https://cryptologos.cc/logos/binance-coin-bnb-logo.svg?v=018",
//         titledescription: "Use Binance Smart Chain to swap and stake tokens",
//         details: [
//             {
//                 description: 'Transfer from Binance to Metamask', 
//                xp:  '100XP',
//                link: 'https://www.google.com/',
//                image: "https://uploads-ssl.webflow.com/61ac493358f04be987c2a86b/61ac493358f04bc73dc2a886_principle.svg"
//             },
//             {
//                 description: 'Swap USDT for a token on PancakeSwap', 
//                xp:  '100XP',
//                link: 'https://www.google.com/',
//                image: "https://uploads-ssl.webflow.com/61ac493358f04be987c2a86b/61ac493358f04bc73dc2a886_principle.svg"
//             },
//             {
//                 description: 'Stake CAKE and Earn Interest',
//                 xp: '100 XP',
//                 link: 'https://www.google.com/',
//                 image: "https://uploads-ssl.webflow.com/61ac493358f04be987c2a86b/61ac493358f04bc73dc2a886_principle.svg"
//             }
//         ]
//     },
//     {
//         title : 'Ethereum',
//         image: "https://cryptologos.cc/logos/polygon-matic-logo.svg?v=018",
//         titledescription: "Use the famous Layer 2 Solution Polygon Matic",
//         details: [
//             {
//                 description: 'Swap Mat for DG', 
//                xp:  '100XP',
//                link: 'https://www.google.com/',
//                image: "https://uploads-ssl.webflow.com/61ac493358f04be987c2a86b/61ac493358f04bc73dc2a886_principle.svg"
//             },
//         ]
//     },
    
// ]


// export default data;

export {
    data
}

// export {
//     platformData
// }