import React from 'react';
import Collection_sm_1 from "../../../assets/images/collection/collection-sm-01.jpg";
import Collection_sm_2 from "../../../assets/images/collection/collection-sm-02.jpg";
import Collection_sm_3 from "../../../assets/images/collection/collection-sm-03.jpg";
import Client_15 from "../../../assets/images/client/client-15.png";
import {data} from "../../content/Data";
import Collection_lg_1 from "../../../assets/images/collection/collection-lg-01.jpg";
import {useNavigate} from 'react-router-dom';
import { productContext } from '../../../App';
import {useContext} from "react"; 


const CollectionDetails = (props) => {
    const {title, platformName, details, image} = props.data
    const [platform, setPlatform] = useContext(productContext);


    const navigate = useNavigate();
    const handleRoute = (name) => {
      setPlatform(name);
    navigate('/product')
    }
    return (
       <div onClick={()=>handleRoute(platformName)}
            data-aos="fade-up"
             data-aos-offset="200"
             data-aos-delay="50"
             data-aos-duration="1000"
           className="col-lg-4 col-xl-3 col-md-6 col-sm-6 col-12"
         >
           <a
             href=""
             className="rn-collection-inner-one"
           >
             <div className="collection-wrapper">
               <div className="collection-big-thumbnail">
                 <img src={image} alt="Nft_Profile" />
               </div>
               <div className="collenction-small-thumbnail">
                 {/* <img src={Collection_sm_1} alt="Nft_Profile" />
                 <img src={Collection_sm_2} alt="Nft_Profile" />
                 <img src={Collection_sm_3} alt="Nft_Profile" /> */}
               </div>
               <div className="collection-profile">
                 {/* <img src={Client_15} alt="Nft_Profile" /> */}
               </div>
               <div className="collection-deg">
                 <h6 className="title">{title}</h6>
                 <span className="items">{details.length} Items</span>
               </div>
             </div>
           </a>
         </div>
    )
}

export default CollectionDetails
