import React from 'react';
import Portfolio1 from "../../../assets/images/portfolio/portfolio-01.jpg";
import { useMoralis } from 'react-moralis';
import { useMoralisQuery } from 'react-moralis';
import { useEffect, useState } from 'react';
import ProductMap from './ProductMap';

const ProductDetails = (props) => {
    
    const {details} = props.data;
  
    //const matchingTaskName = isAuthenticated ? parseData.filter(data => data.CurrentUserAddress == user.get("ethAddress")) : [];
  
    return (
       <>
       {
           details.map((detailsdata) => <ProductMap detailsdata={detailsdata}/>)
        }
       </>
    )
}

export default ProductDetails
