import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Collection_1 from "../../../assets/images/collection/collection-lg-01.jpg";
import Collection_sm from "../../../assets/images/collection/collection-sm-01.jpg";
import Collection_lg_1 from "../../../assets/images/collection/collection-lg-01.jpg";
import Collection_lg_2 from "../../../assets/images/collection/collection-lg-02.jpg";
import Collection_lg_4 from "../../../assets/images/collection/collection-lg-04.jpg";
import Collection_sm_2 from "../../../assets/images/collection/collection-sm-02.jpg";
import Collection_sm_3 from "../../../assets/images/collection/collection-sm-03.jpg";
import Collection_sm_4 from "../../../assets/images/collection/collection-sm-04.jpg";
import Collection_sm_5 from "../../../assets/images/collection/collection-sm-05.jpg";
import Collection_sm_6 from "../../../assets/images/collection/collection-sm-06.jpg";
import Collection_sm_7 from "../../../assets/images/collection/collection-sm-07.jpg";
import Collection_sm_8 from "../../../assets/images/collection/collection-sm-08.jpg";
import Collection_sm_9 from "../../../assets/images/collection/collection-sm-09.jpg";
import Collection_sm_10 from "../../../assets/images/collection/collection-sm-10.jpg";
import Collection_sm_11 from "../../../assets/images/collection/collection-sm-11.jpg";
import Collection_sm_12 from "../../../assets/images/collection/collection-sm-12.jpg";
import Client_14 from "../../../assets/images/client/client-14.png";
import Client_15 from "../../../assets/images/client/client-15.png";
import Client_1 from "../../../assets/images/client/client-1.png";
import Client_3 from "../../../assets/images/client/client-3.png";
import Client_2 from "../../../assets/images/client/client-2.png";
import Client_12 from "../../../assets/images/client/client-12.png";
import Client_13 from "../../../assets/images/client/client-13.png";
import Collection_lg_3 from "../../../assets/images/collection/collection-lg-03.jpg";
import Collection_lg_5 from "../../../assets/images/collection/collection-lg-05.jpg";
import Portfolio1 from "../../../assets/images/portfolio/portfolio-01.jpg";
import {data} from "../../content/Data";
import {useState} from "react";
import ProductDetails from "./ProductDetails";
import { productContext } from '../../../App';
import {useContext, useEffect} from "react"; 


AOS.init();
const Product = () => {
 const [platform, setPlatform] = useContext(productContext);
 const [ecosystemName, setEcosystemName] = useState(data);
 const handleData = (name)=>{
   const matchPlatform = data.find((data)=> data.platformName===name);
   setEcosystemName([matchPlatform]);
 }

 useEffect(() => {
  const matchPlatform = data.find((data)=> data.platformName===platform);
  setEcosystemName([matchPlatform]);
 }, [platform])
 

  return (
    <>
      {/* Start product area */}
      <div className="rn-product-area rn-section-gapTop">
        <div className="container">
          <div className="row mb--50 align-items-center">
            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
              <h3
                className="title mb--0"
                  data-aos="fade-up"
                data-aos-offset="200"
                data-aos-delay="50"
                data-aos-duration="1000"
              >
                {/* Explore Product */}
              </h3>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt_mobile--15" 
            style={{ zIndex: "1",
                    display: "flex",
                    justifyContent: "end"
                    }}>
              {/* <div
                className="view-more-btn text-end text-sm-end"  style={{display: "block"}}
                data-aos="fade-up"
                data-aos-offset="200"
                data-aos-delay="50"
                data-aos-duration="1000"
              > */}
               <div className="button-group isotop-filter filters-button-group d-flex justify-content-start justify-content-lg-end mt_md--30 mt_sm--30">
                {/* <button data-filter="*"  className="is-checked">
                  <span className="filter-text">All Items</span>
                </button> */}
                {
                  data.map((data)=>{
                    return <button onClick={()=>handleData(data.platformName)}  data-filter=".cat--1">
                    <span className="filter-text">{data.platformName}</span>
                  </button>
                  })
                }
              </div>
            </div>
          </div>
          <div className="row g-5">
            {/* start single product */}
            {
            ecosystemName.map((data)=> <ProductDetails data={data}/>)
            }
            {/* end single product */}
          </div>
        </div>
      </div>
      {/* end product area */}
    </>
  );
};

export default Product;
