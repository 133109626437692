import React from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
// ..

import Collection_1 from "../../../assets/images/collection/collection-lg-01.jpg";
import Collection_sm from "../../../assets/images/collection/collection-sm-01.jpg";
import Collection_lg_1 from "../../../assets/images/collection/collection-lg-01.jpg";
import Collection_lg_2 from "../../../assets/images/collection/collection-lg-02.jpg";
import Collection_lg_4 from "../../../assets/images/collection/collection-lg-04.jpg";
import Collection_sm_2 from "../../../assets/images/collection/collection-sm-02.jpg";
import Collection_sm_3 from "../../../assets/images/collection/collection-sm-03.jpg";
import Collection_sm_4 from "../../../assets/images/collection/collection-sm-04.jpg";
import Collection_sm_5 from "../../../assets/images/collection/collection-sm-05.jpg";
import Collection_sm_6 from "../../../assets/images/collection/collection-sm-06.jpg";
import Collection_sm_7 from "../../../assets/images/collection/collection-sm-07.jpg";
import Collection_sm_8 from "../../../assets/images/collection/collection-sm-08.jpg";
import Collection_sm_9 from "../../../assets/images/collection/collection-sm-09.jpg";
import Collection_sm_10 from "../../../assets/images/collection/collection-sm-10.jpg";
import Collection_sm_11 from "../../../assets/images/collection/collection-sm-11.jpg";
import Collection_sm_12 from "../../../assets/images/collection/collection-sm-12.jpg";
import Client_14 from "../../../assets/images/client/client-14.png";
import Client_15 from "../../../assets/images/client/client-15.png";
import Client_1 from "../../../assets/images/client/client-1.png";
import Client_3 from "../../../assets/images/client/client-3.png";
import Client_2 from "../../../assets/images/client/client-2.png";
import Client_12 from "../../../assets/images/client/client-12.png";
import Client_13 from "../../../assets/images/client/client-13.png";
import Collection_lg_3 from "../../../assets/images/collection/collection-lg-03.jpg";
import Collection_lg_5 from "../../../assets/images/collection/collection-lg-05.jpg";
import Portfolio1 from "../../../assets/images/portfolio/portfolio-01.jpg";
import Collectiondetails from "./Collectiondetails";
import {data} from "../../content/Data";
import Taskdetails from "./TaskDetails";
import {useState} from "react";

AOS.init();

const Collection = () => {
  const [platform, setPlatform] = useState(data);
  const handleData = (platformName)=>{
    const matchPlatform = data.find((data)=> data.platformName===platformName);
    setPlatform([matchPlatform]);
  }

  return (
    <>
      {/* collection area Start */}
      <div className="rn-collection-area rn-section-gapTop">
        <div className="container">
          <div className="row mb--50 align-items-center">
            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
              <h3
                className="title mb--0 sal-animate"
                data-aos="fade-up"
                data-aos-offset="200"
                data-aos-delay="50"
                data-aos-duration="1000"
              >
                Platforms
              </h3>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt_mobile--15">
              <div
                className="view-more-btn text-start text-sm-end"
                data-aos="fade-up"
                data-aos-offset="200"
                data-aos-delay="50"
                data-aos-duration="1000"
              >
                <a className="btn-transparent" href="/collection">
                  VIEW ALL
                  <i data-feather="arrow-right" />
                </a>
              </div>
            </div>
          </div>
          <div className="row g-5">
            {/* start single collention */}
            {
            data.map((data)=> <Collectiondetails data={data}/>)
            }
            {/* End single collention */}
          </div>
        </div>
      </div>
      {/* collection area End */}
      {/* Start product area */}
      <div className="rn-product-area rn-section-gapTop masonary-wrapper-activation">
        <div className="container">
          <div className="row mb--30 align-items-center">
            <div className="col-lg-4">
              <div className="section-title">
                <h3 className="title mb--0">Explore Tasks</h3>
              </div>
            </div>
            <div className="col-lg-8"> 
              <div className="button-group isotop-filter filters-button-group d-flex justify-content-start justify-content-lg-end mt_md--30 mt_sm--30">
                {/* <button data-filter="*"  className="is-checked">
                  <span className="filter-text">All Items</span>
                </button> */}
                {
                  data.map((data)=>{
                    return <button onClick={()=>handleData(data.platformName)}  data-filter=".cat--1">
                    <span className="filter-text">{data.platformName}</span>
                  </button>
                  })
                }
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="grid-metro5 mesonry-list">
              <div className="resizer" />
              {/* start single product */}
              {
            platform.map((data)=> <Taskdetails data={data}/>)
            }
              {/* end single product */}
            </div>
          </div>
        </div>
      </div>
      {/* end product area */}
      {/* <div className="rn-creator-title-area rn-section-gapTop">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
              <h2 className="title mb--0">Our Best Creators</h2>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt_mobile--15">
              <div className="shortby-default text-start text-sm-end">
                <label className="filter-leble">SHOT BY:</label>
                <select>
                  <option data-display="Top Rated">Top Rated</option>
                  <option value={1}>Best Seller</option>
                  <option value={2}>Recent Added</option>
                  <option value={3}>Varified</option>
                </select>
              </div>
            </div>
          </div>
          <div className="row g-5 mt--30 creator-list-wrapper">
            start single top-seller
            <div className="creator-single col-lg-3 col-md-4 col-sm-6">
              <div className="top-seller-inner-one explore">
                <div className="top-seller-wrapper">
                  <div className="thumbnail varified">
                    <a >
                      <img src={Client_1} alt="Nft_Profile" />
                    </a>
                  </div>
                  <div className="top-seller-content">
                    <a >
                      <h6 className="name">Brodband</h6>
                    </a>
                    <span className="count-number">$2500,000</span>
                  </div>
                </div>
                <a className="over-link"  />
              </div>
            </div>
            End single top-seller
            start single top-seller
            <div className="creator-single col-lg-3 col-md-4 col-sm-6">
              <div className="top-seller-inner-one explore">
                <div className="top-seller-wrapper">
                  <div className="thumbnail varified">
                    <a >
                      <img src={Client_1} alt="Nft_Profile" />
                    </a>
                  </div>
                  <div className="top-seller-content">
                    <a >
                      <h6 className="name">Marks</h6>
                    </a>
                    <span className="count-number">$2200,000</span>
                  </div>
                </div>
                <a className="over-link"  />
              </div>
            </div>
            End single top-seller
            start single top-seller
            <div className="creator-single col-lg-3 col-md-4 col-sm-6">
              <div className="top-seller-inner-one explore">
                <div className="top-seller-wrapper">
                  <div className="thumbnail varified">
                    <a >
                      <img src={Client_1} alt="Nft_Profile" />
                    </a>
                  </div>
                  <div className="top-seller-content">
                    <a >
                      <h6 className="name">JOne Lee</h6>
                    </a>
                    <span className="count-number">$900,000</span>
                  </div>
                </div>
                <a className="over-link"  />
              </div>
            </div>
            End single top-seller
            start single top-seller
            <div className="creator-single col-lg-3 col-md-4 col-sm-6">
              <div className="top-seller-inner-one explore">
                <div className="top-seller-wrapper">
                  <div className="thumbnail varified">
                    <a >
                      <img src={Client_1} alt="Nft_Profile" />
                    </a>
                  </div>
                  <div className="top-seller-content">
                    <a >
                      <h6 className="name">Malinga</h6>
                    </a>
                    <span className="count-number">$2400,000</span>
                  </div>
                </div>
                <a className="over-link"  />
              </div>
            </div>
            End single top-seller
            start single top-seller
            <div className="creator-single col-lg-3 col-md-4 col-sm-6">
              <div className="top-seller-inner-one explore">
                <div className="top-seller-wrapper">
                  <div className="thumbnail varified">
                    <a >
                      <img src={Client_1} alt="Nft_Profile" />
                    </a>
                  </div>
                  <div className="top-seller-content">
                    <a >
                      <h6 className="name">Favis</h6>
                    </a>
                    <span className="count-number">$290,000</span>
                  </div>
                </div>
                <a className="over-link"  />
              </div>
            </div>
            End single top-seller
            start single top-seller
            <div className="creator-single col-lg-3 col-md-4 col-sm-6">
              <div className="top-seller-inner-one explore">
                <div className="top-seller-wrapper">
                  <div className="thumbnail varified">
                    <a >
                      <img src={Client_1} alt="Nft_Profile" />
                    </a>
                  </div>
                  <div className="top-seller-content">
                    <a >
                      <h6 className="name">Fakir</h6>
                    </a>
                    <span className="count-number">$299,00,000</span>
                  </div>
                </div>
                <a className="over-link"  />
              </div>
            </div>
            End single top-seller
            start single top-seller
            <div className="creator-single col-lg-3 col-md-4 col-sm-6">
              <div className="top-seller-inner-one explore">
                <div className="top-seller-wrapper">
                  <div className="thumbnail varified">
                    <a >
                      <img src={Client_1} alt="Nft_Profile" />
                    </a>
                  </div>
                  <div className="top-seller-content">
                    <a >
                      <h6 className="name">Sajib</h6>
                    </a>
                    <span className="count-number">$1100,000</span>
                  </div>
                </div>
                <a className="over-link"  />
              </div>
            </div>
            End single top-seller
            start single top-seller
            <div className="creator-single col-lg-3 col-md-4 col-sm-6">
              <div className="top-seller-inner-one explore">
                <div className="top-seller-wrapper">
                  <div className="thumbnail varified">
                    <a >
                      <img src={Client_1} alt="Nft_Profile" />
                    </a>
                  </div>
                  <div className="top-seller-content">
                    <a >
                      <h6 className="name">Mikel</h6>
                    </a>
                    <span className="count-number">$2500,000</span>
                  </div>
                </div>
                <a className="over-link"  />
              </div>
            </div>
            End single top-seller
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Collection;
