import React from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
// ..
import Collection_lg_1 from "../../../assets/images/collection/collection-lg-01.jpg";
import Collection_lg_2 from "../../../assets/images/collection/collection-lg-02.jpg";
import Collection_lg_4 from "../../../assets/images/collection/collection-lg-04.jpg";
import Collection_sm_1 from "../../../assets/images/collection/collection-sm-01.jpg";
import Collection_sm_2 from "../../../assets/images/collection/collection-sm-02.jpg";
import Collection_sm_3 from "../../../assets/images/collection/collection-sm-03.jpg";
import Collection_sm_4 from "../../../assets/images/collection/collection-sm-04.jpg";
import Collection_sm_5 from "../../../assets/images/collection/collection-sm-05.jpg";
import Collection_sm_6 from "../../../assets/images/collection/collection-sm-06.jpg";
import Collection_sm_7 from "../../../assets/images/collection/collection-sm-07.jpg";
import Collection_sm_8 from "../../../assets/images/collection/collection-sm-08.jpg";
import Collection_sm_9 from "../../../assets/images/collection/collection-sm-09.jpg";
import Collection_sm_10 from "../../../assets/images/collection/collection-sm-10.jpg";
import Collection_sm_11 from "../../../assets/images/collection/collection-sm-11.jpg";
import Collection_sm_12 from "../../../assets/images/collection/collection-sm-12.jpg";
import Client_14 from "../../../assets/images/client/client-14.png";
import Client_15 from "../../../assets/images/client/client-15.png";
import Client_12 from "../../../assets/images/client/client-12.png";
import Client_13 from "../../../assets/images/client/client-13.png";
import Collection_lg_3 from "../../../assets/images/collection/collection-lg-03.jpg";
import Collection_lg_5 from "../../../assets/images/collection/collection-lg-05.jpg";
import { createPortal } from "react-dom";
import {data} from "../../content/Data";
import CollectionDetails from "./CollectionDetails";
import {useState} from "react";



AOS.init();
const Collection = (props) => {
  return (
    <>
      {/* collection area Start */}
      <div className="rn-collection-area rn-section-gapTop">
        <div className="container">
          <div className="row g-5">
            {/* start single collention */}
            {
              data.map((data)=><CollectionDetails data={data}/>)
            }
            {/* End single collention */}
          </div>
          {/* <div className="row">
            <div
              className="col-lg-12"
             data-aos="fade-up"
                data-aos-offset="200"
                data-aos-delay="50"
                data-aos-duration="1000"
            >
              <nav
                className="pagination-wrapper"
                aria-label="Page navigation example"
              >
                <ul className="pagination">
                  <li className="page-item">
                    <a className="page-link" href="#">
                      Previous
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link active" href="#">
                      1
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      2
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      3
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      Next
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div> */}
        </div>
      </div>
      {/* collection area End */}
    </>
  );
};

export default Collection;
