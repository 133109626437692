import React from 'react'
import Portfolio1 from "../../../assets/images/portfolio/portfolio-01.jpg";
import { useMoralis } from 'react-moralis';
import { useMoralisQuery } from 'react-moralis';
import { useState, useEffect } from 'react';


const TaskDetails = (props) => {

    const {details} = props.data
    const { authenticate, isAuthenticated, user } = useMoralis();
    // const [matchingName, setMatchingName] = useState({});
    
    const {taskName,description,xp,link,image} = details
    

    const userData = localStorage.getItem("userData") ? localStorage.getItem("userData") : [] ; 

    const parseData = JSON.parse(userData);
    console.log(parseData, "This is parseData");
    console.log(userData, "This is JSON");

    return (
        <>
            {
                details.map((data)=>{
                    const {taskName,description,xp,link,image} = data
                    const matchingName = parseData.length > 0 && parseData.find(data => data.taskName === taskName);
                    return  <div className="grid-metro-item cat--1 cat--3">
                    <div className="product-style-one no-overlay">
                      <div className="card-thumbnail">
                        <a >
                          <img src={image} alt="NFT_portfolio" />
                        </a>
                      </div>
                      <div className="product-share-wrapper">
                        <div className="profile-share">
                         
                        </div>
                        <div className="share-btn share-btn-activation dropdown">
                          <button
                            className="icon"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <svg
                              viewBox="0 0 14 4"
                              fill="none"
                              width={16}
                              height={16}
                              className="sc-bdnxRM sc-hKFxyN hOiKLt"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M3.5 2C3.5 2.82843 2.82843 3.5 2 3.5C1.17157 3.5 0.5 2.82843 0.5 2C0.5 1.17157 1.17157 0.5 2 0.5C2.82843 0.5 3.5 1.17157 3.5 2ZM8.5 2C8.5 2.82843 7.82843 3.5 7 3.5C6.17157 3.5 5.5 2.82843 5.5 2C5.5 1.17157 6.17157 0.5 7 0.5C7.82843 0.5 8.5 1.17157 8.5 2ZM11.999 3.5C12.8274 3.5 13.499 2.82843 13.499 2C13.499 1.17157 12.8274 0.5 11.999 0.5C11.1706 0.5 10.499 1.17157 10.499 2C10.499 2.82843 11.1706 3.5 11.999 3.5Z"
                                fill="currentColor"
                              />
                            </svg>
                          </button>
                          <div className="share-btn-setting dropdown-menu dropdown-menu-end">
                            <button
                              type="button"
                              className="btn-setting-text share-text"
                              data-bs-toggle="modal"
                              data-bs-target="#shareModal"
                            >
                              Share
                            </button>
                            <button
                              type="button"
                              className="btn-setting-text report-text"
                              data-bs-toggle="modal"
                              data-bs-target="#reportModal"
                            >
                              Report
                            </button>
                          </div>
                        </div>
                      </div>
                      <a >
                        <span className="product-name">{taskName} {xp}</span>
                        
                      </a>
                      <span className="latest-bid">{description}</span>
                      <div className="bid-react-area">
                        <div  className="last-bid"><a href={link}>Read Task</a></div>
                        <div className="react-area">
                          
                        <span className="number"> 
              {
                 isAuthenticated ?
               <div>
                    {
                        matchingName ?
                            <button className="title">COMPLETED</button>
                            :
                            <a href={link} target="_blank" rel="no noreferrer" style={{ textDecoration: 'none' }}><button>start Task</button></a>
                    }
                </div>
                    :
               <button onClick={() => authenticate()}>start Task</button>
           }
           </span>
                        </div>
                      </div>
                    </div>
                  </div>        
                })

            } 
        </>  
    )
}

export default TaskDetails
