import React from 'react';
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles

import Collection_1 from "../../../assets/images/collection/collection-lg-01.jpg";
import Collection_sm from "../../../assets/images/collection/collection-sm-01.jpg";
import Collection_lg_1 from "../../../assets/images/collection/collection-lg-01.jpg";
import Collection_lg_2 from "../../../assets/images/collection/collection-lg-02.jpg";
import Collection_lg_4 from "../../../assets/images/collection/collection-lg-04.jpg";
import Collection_sm_2 from "../../../assets/images/collection/collection-sm-02.jpg";
import Collection_sm_3 from "../../../assets/images/collection/collection-sm-03.jpg";
import Collection_sm_4 from "../../../assets/images/collection/collection-sm-04.jpg";
import Collection_sm_5 from "../../../assets/images/collection/collection-sm-05.jpg";
import Collection_sm_6 from "../../../assets/images/collection/collection-sm-06.jpg";
import Collection_sm_7 from "../../../assets/images/collection/collection-sm-07.jpg";
import Collection_sm_8 from "../../../assets/images/collection/collection-sm-08.jpg";
import Collection_sm_9 from "../../../assets/images/collection/collection-sm-09.jpg";
import Collection_sm_10 from "../../../assets/images/collection/collection-sm-10.jpg";
import Collection_sm_11 from "../../../assets/images/collection/collection-sm-11.jpg";
import Collection_sm_12 from "../../../assets/images/collection/collection-sm-12.jpg";
import Client_14 from "../../../assets/images/client/client-14.png";
import Client_15 from "../../../assets/images/client/client-15.png";
import Client_1 from "../../../assets/images/client/client-1.png";
import Client_3 from "../../../assets/images/client/client-3.png";
import Client_2 from "../../../assets/images/client/client-2.png";
import Client_12 from "../../../assets/images/client/client-12.png";
import Client_13 from "../../../assets/images/client/client-13.png";
import Collection_lg_3 from "../../../assets/images/collection/collection-lg-03.jpg";
import Collection_lg_5 from "../../../assets/images/collection/collection-lg-05.jpg";
import Portfolio1 from "../../../assets/images/portfolio/portfolio-01.jpg";
import {useNavigate} from 'react-router-dom';
import { productContext } from '../../../App';
import {useContext} from "react"; 

AOS.init();

const Collectiondetails = (props) => {
    const { platformName, details, image } = props.data
    const [platform, setPlatform] = useContext(productContext);

    const navigate = useNavigate();
    const handleRoute = (name) => {
    setPlatform(name)
    navigate('/product')
    }
    return (
        <div onClick={()=>handleRoute(platformName)}
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="1000"
        className="col-lg-4 col-xl-3 col-md-6 col-sm-6 col-12 sal-animate"
      >
        <div className="rn-collection-inner-one">
          <div className="collection-wrapper">
          <div className="collection-big-thumbnail">
                    <img src={image} alt="Nft_Profile" />
                  </div>
                  <div className="collenction-small-thumbnail">
                    {/* <img src={Collection_sm_4} alt="Nft_Profile" />
                    <img src={Collection_sm_5} alt="Nft_Profile" /> */}
                    {/* <img src={Collection_sm_6} alt="Nft_Profile" /> */}
                  </div>
                  <div className="collection-profile">
                    {/* <img src={Client_12} alt="Nft_Profile" /> */}
                  </div>
            <div className="collection-deg">
              <h6 className="title">{platformName}</h6>
              <span className="items">{details.length} Tasks</span>
            </div>
          </div>
        </div>
      </div>
    )
}


export default Collectiondetails
